import { useEffect, useState } from 'react';
import { MODAL_ANIMATION_TIME } from '../utils/helpers/constants';

export const useAnimationDelay = (isOpen, setAnimation) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setAnimate(true);
      if (setAnimation) setAnimation(true);
    } else if (!isOpen && animate) {
      const timer = setTimeout(() => {
        setAnimate(false);
        if (setAnimation) setAnimation(false);
      }, MODAL_ANIMATION_TIME);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  return animate;
};
