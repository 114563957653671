import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import getTabBarItems from '../../utils/helpers/getTabBarItems';
import { TabBarItem } from './TabBarItem';
import { useBridgeState } from '../../bridge';
import SelectLeasedModal from '../ItemCreationForm/SelectLeasedModal';
import { useNewItemLocalState } from '../../hooks/useNewItemLocalState';
import { toggleAddNewItemModal } from '../../actions';
import { ICustomTabBarItem } from './types';
import { TabBarContainer, TabBarWrapper, TabBarList } from './styled';
import useScreenSize from '../../hooks/useScreenSize';
import { useClient } from '../../hooks/useClient';

interface RootState {
  addNewItemModal: {
    isVisible: boolean;
  };
}

export const TabBarNavigation: React.FC<{ isAuthInProgress: boolean }> = ({
  isAuthInProgress
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isSmall } = useScreenSize();
  const isClient = useClient();

  const { isWebView, insets } = useBridgeState() as {
    isWebView: boolean;
    insets: { bottom: number };
  };

  const isAddFormVisible = useSelector(
    (state: RootState) => state.addNewItemModal.isVisible
  );

  const [animation, setAnimation] = useState(false);

  const { newItemFirstModalValue, newItemSecondModalValue, setSessionKey } =
    useNewItemLocalState();

  const bottomInsetStyles = useMemo<React.CSSProperties | undefined>(() => {
    if (isWebView) {
      return { height: insets.bottom + 53 };
    }
    return undefined;
  }, [isWebView, insets]);

  const tabBarItems: ICustomTabBarItem[] = getTabBarItems(t);

  useEffect(() => {
    if (newItemFirstModalValue || newItemSecondModalValue) {
      dispatch(toggleAddNewItemModal(true));
    }
    setSessionKey();
  }, [
    dispatch,
    newItemFirstModalValue,
    newItemSecondModalValue,
    setSessionKey
  ]);

  if (isClient && !isSmall) return null;

  return (
    <TabBarContainer>
      <TabBarWrapper style={bottomInsetStyles}>
        <TabBarList>
          {tabBarItems.map((item, i) => (
            <TabBarItem
              key={`item_${i}`}
              item={item}
              isAuthInProgress={isAuthInProgress}
            />
          ))}
        </TabBarList>
      </TabBarWrapper>

      {(isAddFormVisible || animation) && (
        <SelectLeasedModal
          isOpen={isAddFormVisible}
          setAnimation={setAnimation}
        />
      )}
    </TabBarContainer>
  );
};
