import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon';

const OptionComponent = ({
  icon,
  text,
  stat,
  onClick,
  cypress,
  iconSize = 30
}) => (
  <div
    tabIndex={0}
    role="button"
    data-stat={stat}
    onClick={onClick}
    onKeyPress={onClick}
    className="option-container"
  >
    {icon && (
      <Icon
        IconComponent={icon}
        width={iconSize}
        height={iconSize}
        indent={false}
      />
    )}
    <span className="option__text" data-cy={cypress}>
      {text}
    </span>
  </div>
);

OptionComponent.propTypes = {
  icon: PropTypes.func,
  stat: PropTypes.string,
  onClick: PropTypes.func,
  cypress: PropTypes.string,
  iconSize: PropTypes.number,
  text: PropTypes.string.isRequired
};

export default OptionComponent;
