import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import Link from 'next/link';
import DesktopSwitcher from '../../DesktopSwitcher';
import {
  AboutContainer,
  DesktopSwitcherContainer,
  StyledLinkTitle
} from './styled';
import { STATIC_PAGES } from '../../../utils/helpers/constants';
import CONFIG from '../../../config';

const About = () => {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const isShowDeviceSwitcher = !CONFIG.adaptivePageRoutes.includes(
    router.route
  );

  const aboutItemsList = [
    {
      title: t('footer.about'),
      path: STATIC_PAGES.ABOUT
    },
    {
      title: t('footer.agreement'),
      path: STATIC_PAGES.TERMS
    },
    {
      title: t('footer.sitemap'),
      path: '/pages/sitemap',
      defaultLink: true
    },
    {
      title: t('footer.privacy'),
      path: STATIC_PAGES.PRIVACY
    },
    {
      title: t('footer.advertise'),
      path: CONFIG.getLandingPageUrl('footer', i18n.language),
      dataStat: 'advertising-landing-footer-btn'
    }
  ];

  return (
    <AboutContainer data-cy="about">
      {aboutItemsList.map(({ title, path, defaultLink, dataStat }, index) => {
        return (
          <div data-cy="footer-link-pages" key={index}>
            <StyledLinkTitle
              as={defaultLink ? 'a' : Link}
              data-cy="about-title"
              href={path}
              data-stat={dataStat}
              locale={false}
            >
              {title}
            </StyledLinkTitle>
          </div>
        );
      })}
      <div data-cy="footer-link-pages">
        {isShowDeviceSwitcher && (
          <DesktopSwitcherContainer>
            <DesktopSwitcher />
          </DesktopSwitcherContainer>
        )}
      </div>
    </AboutContainer>
  );
};

export default About;
