import React from 'react';
import cn from 'classnames';
import {
  Mark,
  MarkContainer,
  RadioContainer,
  RadioInput,
  RadioText
} from './styled';
import { NewRadioButtonProps } from './types';

export const NewRadioButton = ({
  data,
  name,
  color,
  stat,
  cypress,
  currentValue,
  onChangeHandler,
  containerClassNames,
  additionalClasses,
  markerPosition = 'right'
}: NewRadioButtonProps) => {
  const classes = cn(containerClassNames, additionalClasses);
  const isChecked = currentValue === data.id;

  return (
    <RadioContainer
      className={classes}
      data-cy="radio-container"
      data-stat={stat}
      markerPosition={markerPosition}
    >
      <RadioInput
        type="radio"
        name={name}
        value={data.id}
        onChange={onChangeHandler}
        data-cy={`${cypress}-${data.id}`}
        checked={isChecked}
      />
      <RadioText
        tag="body_2"
        color={color}
        $isChecked={isChecked}
        data-cy={cypress}
      >
        {data.name}
      </RadioText>
      <MarkContainer>
        <Mark />
      </MarkContainer>
    </RadioContainer>
  );
};
