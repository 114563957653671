import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const LinkWithIcon = ({
  cypress,
  stat,
  onClick,
  onKeyPress,
  role,
  tabIndex,
  href,
  icon,
  width,
  height,
  color,
  indent,
  styles,
  ...rest
}) => {
  return (
    <a
      data-cy={cypress}
      data-stat={stat}
      onKeyPress={onKeyPress}
      {...{ role, tabIndex, onClick, href, ...rest }}
      style={{ ...styles, cursor: 'pointer' }}
    >
      <Icon IconComponent={icon} {...{ width, height, color, indent }} />
    </a>
  );
};

LinkWithIcon.propTypes = {
  cypress: PropTypes.string,
  stat: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  role: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  href: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func
  ]),
  width: PropTypes.number,
  height: PropTypes.number,
  indent: PropTypes.bool,
  color: PropTypes.string,
  styles: PropTypes.object
};

export default LinkWithIcon;
