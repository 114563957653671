import styled from 'styled-components';
import { FlexColumn, FlexibleDiv } from '../../../styled/common';
import { FontWeight } from '../../../styled/constants';

export const ContactUsWrapper = styled(FlexibleDiv)`
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.primary};

  &:first-child {
    padding-right: 0;
  }

  @media screen and (max-width: 1439px) {
    padding-bottom: 15px;
  }

  @media screen and (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
    flex-direction: column;
    padding: 10px 0;
  }
`;

export const ContactsInfoBlock = styled(FlexibleDiv)`
  align-items: center;

  @media screen and (max-width: 1439px) {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
`;

export const TurboAppLinksBlock = styled(FlexibleDiv)`
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;

  @media screen and (max-width: 1439px) {
    padding: 10px 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
    margin-top: 20px;
    justify-content: flex-start;
  }
`;

export const ContactsInfoItems = styled(FlexibleDiv)`
  gap: 30px;

  @media screen and (max-width: 1439px) {
    gap: 10px;
  }
`;

export const ContactsInfoItem = styled(FlexColumn)`
  align-items: flex-start;
`;

export const ContactsInfoItemTitle = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: ${FontWeight.thin};
  margin-bottom: 2px;

  @media screen and (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
    font-size: 13px;
    margin-bottom: 10px;
  }
`;

export const ContactsInfoBlockTitle = styled(ContactsInfoItemTitle)`
  display: block;
  margin-bottom: 14px;
  font-size: 13px;

  @media screen and (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
    margin-bottom: 10px;
  }
`;

export const ContactsInfoItemValue = styled.a`
  width: max-content;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: ${FontWeight.thin};
  line-height: normal;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.systemColors.brand};
  }

  @media screen and (max-width: 1439px) {
    font-size: 13px;
  }

  @media screen and (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
    font-size: 15px;
  }
`;

export const QrCodeInfoContainer = styled(FlexColumn)`
  padding: 10px 15px 10px 0;
  gap: 5px;

  @media screen and (max-width: 1439px) {
    padding: 0;
  }
`;

export const TurboAppQrCodeLinked = styled.a`
  text-decoration: none;
`;

export const TurboAppLinkedTitle = styled.span`
  display: block;
  font-size: 13px;
  font-weight: ${FontWeight.thin};
  text-decoration: none;

  @media screen and (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
    font-size: 15px;
    text-decoration: underline;
  }
`;

export const TurboAppDescription = styled.span`
  display: block;
  width: 131px;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 10px;
  font-weight: ${FontWeight.thin};

  @media screen and (max-width: 1439px) {
    width: 100%;
    font-size: 12px;
  }
`;
