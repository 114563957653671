import styled, { css } from 'styled-components';

interface TabBarLinkProps {
  centered?: boolean;
  $active?: boolean;
}

export const TabBarContainer = styled.div.attrs(() => ({
  'data-cy': 'tab-bar-container'
}))`
  position: relative;

  @media screen and (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    display: none;
  }
`;

export const TabBarWrapper = styled.div`
  position: fixed;
  min-height: 53px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 15px calc(env(safe-area-inset-bottom) + 4px);
  border-top: 1px solid #eae5e1;
  background: #fff;
  z-index: 99;
  transition: transform 0.2s linear;
`;

export const TabBarList = styled.ul.attrs(() => ({
  'data-cy': 'tab-bar-list'
}))`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 11px;
`;

export const TabBarListItem = styled.li.attrs(() => ({
  'data-cy': 'tab-bar-list-item'
}))`
  position: relative;
  width: 20%;
  max-width: 60px;
  text-align: center;
`;

export const TabBarLink = styled.div<TabBarLinkProps>`
  height: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;

  ${({ centered }) =>
    centered &&
    css`
      position: absolute;
      padding: 0;
      height: auto;
      gap: 5px;
      left: 50%;
      top: -41px;
      transform: translate(-50%);
    `}
`;

export const TabBarIconWrapper = styled.i.attrs(() => ({
  'data-cy': 'tab-bar-icon'
}))`
  align-self: center;
`;

export const TabBarLinkText = styled.span<{ $active?: boolean }>`
  font-family: Helvetica, Arial, sans-serif;
  color: ${({ $active }) => ($active ? '#2f1f19' : '#98918a')};
  text-align: center;
  font-size: 8px;
  text-transform: uppercase;
`;

export const HiddenBar = styled.div`
  transform: translateY(145%);
`;
