import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '../../Icon';
import PassIcon from '../../../public/static/images/new_ad/icons/icon-pass.svg';
import CloseIcon from '../../../public/static/images/new_ad/icons/icon-close.svg';
import CalendarIcon from '../../../public/static/images/new_ad/icons/icon-calendar.svg';
import { toggleAddNewItemModal } from '../../../actions';
import OptionComponent from '../OptionComponent';
import SelectCategoryModal from '../SelectCategoryModal';
import { useNewItemLocalState } from '../../../hooks/useNewItemLocalState';
import { useLockBodyScroll } from '../../../hooks/useLockBodyScroll';
import { useAnimationDelay } from '../../../hooks/useAnimationDelay';

const SelectLeasedModal = ({ isOpen, setAnimation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpenCategoriesModal, setIsOpenCategoriesModal] = useState(false);
  const animate = useAnimationDelay(isOpen, setAnimation);
  const {
    newItemSecondModalValue,
    setFirstViewValue,
    setSecondViewValue,
    removeSecondViewValue,
    removeFirstViewValue
  } = useNewItemLocalState();
  useLockBodyScroll(true);

  const closeModal = () => {
    removeFirstViewValue();
    removeSecondViewValue();
    dispatch(toggleAddNewItemModal(false));
  };

  const toggleCategoriesModal = () => setIsOpenCategoriesModal(value => !value);
  const onHandleBackClick = () => {
    setIsOpenCategoriesModal(false);
    removeSecondViewValue();
  };

  const leasedConfig = [
    {
      icon: PassIcon,
      text: t('new_ad.sell_estate'),
      onClick: () => {
        setFirstViewValue('false');
        setSecondViewValue('1');
        toggleCategoriesModal();
      }
    },
    {
      icon: CalendarIcon,
      text: t('new_ad.rent_estate'),
      onClick: () => {
        setFirstViewValue('true');
        setSecondViewValue('1');
        toggleCategoriesModal();
      }
    }
  ];

  useEffect(() => {
    if (newItemSecondModalValue) {
      toggleCategoriesModal();
    }
  }, []);

  return (
    <>
      {isOpen && (
        <div
          tabIndex={0}
          role="button"
          onClick={closeModal}
          onKeyDown={closeModal}
          className="leased-modal__overlay"
        />
      )}
      <div
        className={cn('item-modal', {
          opened: isOpen,
          closed: !isOpen
        })}
      >
        {(isOpen || animate) && (
          <div className="leased-modal">
            <div className="leased-modal__header">
              {t('new_ad.title')}
              <button
                className="leased-modal__header__close-btn"
                onClick={closeModal}
              >
                <Icon IconComponent={CloseIcon} indent={false} />
              </button>
            </div>
            <div className="leased-modal__content">
              {leasedConfig.map((item, i) => (
                <OptionComponent
                  key={i}
                  text={item.text}
                  icon={item.icon}
                  onClick={item.onClick}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <SelectCategoryModal
        hide={onHandleBackClick}
        closeLeasedModal={closeModal}
        isOpen={isOpenCategoriesModal}
      />
    </>
  );
};

SelectLeasedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setAnimation: PropTypes.func.isRequired
};

export default SelectLeasedModal;
