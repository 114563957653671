import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sortIntoVerticalColumns } from '../../../utils/helpers/sortIntoVerticalColumns';
import {
  LocationsBlock,
  LocationsContainer,
  LocationsTitle,
  LocationTitle
} from './styled';
import useScreenSize from '../../../hooks/useScreenSize';
import { StaticFooterDataQuery } from '../../../graphql/graphql';
import { Cities } from '../../../utils/helpers/constants';
import { staticFooterDataQuery } from '../../../queries';
import { useStaticData } from '../../../hooks/useStaticData';
import FooterLocationsPlaceholder from '../../Placeholders/FooterLocationsPlaceholder';
import { useClient } from '../../../hooks/useClient';

export const Locations: React.FC<{
  footerData?: {
    regions: StaticFooterDataQuery['regions'];
    citiesUnPopularList: StaticFooterDataQuery['cities'];
  };
}> = ({ footerData }) => {
  const { t } = useTranslation();
  const sizes = useScreenSize();
  const isClient = useClient();

  const { data, loading } = useStaticData<StaticFooterDataQuery>(
    staticFooterDataQuery,
    !!footerData?.citiesUnPopularList?.length
  );

  const initialData = footerData?.citiesUnPopularList || data?.cities || [];

  const [cities, setCities] = useState(initialData);

  useEffect(() => {
    if (sizes.isSmall && initialData.length === cities.length) return;

    const sortedData = sortIntoVerticalColumns<StaticFooterDataQuery['cities']>(
      initialData,
      7
    );
    setCities(sizes.isSmall ? initialData : sortedData);
  }, [isClient, sizes.isSmall, data?.cities]);

  const regions = footerData?.regions?.[Cities.Baku.id] || data?.regions || [];

  return (
    <LocationsContainer data-cy="locations">
      {loading && !regions.length ? (
        <FooterLocationsPlaceholder />
      ) : (
        <>
          <LocationsTitle data-cy="areas-title">
            {t('common.baki_areas')}
          </LocationsTitle>
          <LocationsBlock data-cy="areas-block">
            {regions.map(region => {
              return (
                <LocationTitle
                  data-cy="area"
                  key={region.slug}
                  href={`/${region.slug}`}
                >
                  {region.name}
                </LocationTitle>
              );
            })}
          </LocationsBlock>
        </>
      )}
      {loading && !cities.length ? (
        <FooterLocationsPlaceholder />
      ) : (
        <>
          <LocationsTitle data-cy="cities">
            {t('common.all_country')}
          </LocationsTitle>
          <LocationsBlock data-cy="cities-block">
            {cities.map((city, index) => {
              return city ? (
                <LocationTitle
                  data-cy="cities-item-link"
                  key={`${city?.id}_${index}`}
                  href={`/${city?.slug}`}
                >
                  {city?.name}
                </LocationTitle>
              ) : (
                <span key={index} />
              );
            })}
          </LocationsBlock>
        </>
      )}
    </LocationsContainer>
  );
};
