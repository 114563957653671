import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ChangeLang from '../../MenuList/ChangeLang';
import Icon from '../../Icon';
import LanguageIcon from '../../../public/static/images/language.svg';
import FacebookIcon from '../../../public/static/images/social/facebook.svg';
import InstagramIcon from '../../../public/static/images/social/instagram.svg';
import {
  ChangeLanguageButtonContainer,
  CopyrightAuthorRules,
  CopyrightContainer,
  GrayLinkWithIcon,
  GrayText,
  LinksContainer
} from './styled';

const Copyright = ({ facebookLink, currentYear, instagramLink }) => {
  const { t, i18n } = useTranslation();

  return (
    <CopyrightContainer data-cy="copyright">
      <GrayText data-cy="copyright-text">
        {t('footer.info')}
        <br />
        <CopyrightAuthorRules>
          {`© 2008-${currentYear} Digital Classifieds MMC.`}
          {'VÖEN: 1405631661'}
        </CopyrightAuthorRules>
      </GrayText>
      <LinksContainer>
        <GrayLinkWithIcon
          href={facebookLink}
          stat="copyright-link-social"
          data-cy="copyright-link-social"
          icon={FacebookIcon}
          width={16}
          height={16}
          indent={false}
          target="_blank"
          aria-label="Open Facebook"
        />
        <GrayLinkWithIcon
          href={instagramLink}
          stat="copyright-link-social"
          data-cy="copyright-link-social"
          icon={InstagramIcon}
          width={16}
          height={16}
          indent={false}
          target="_blank"
          aria-label="Open Instagram"
        />
        <ChangeLanguageButtonContainer>
          <Icon
            IconComponent={LanguageIcon}
            width={16}
            height={16}
            indent={false}
          />
          <ChangeLang i18n={i18n} t={t} />
        </ChangeLanguageButtonContainer>
      </LinksContainer>
    </CopyrightContainer>
  );
};

Copyright.propTypes = {
  facebookLink: PropTypes.string,
  currentYear: PropTypes.number,
  instagramLink: PropTypes.string
};

export default Copyright;
