import React from 'react';

const FooterLocationsPlaceholder = () => {
  return (
    <div className="placeholder-footer">
      <div className="placeholder-row placeholder-row--mt--10 placeholder-footer__bottom-agreement animation" />
      <div className="placeholder-row placeholder-row--mt--10  placeholder-footer__bottom-map animation" />
      <div className="placeholder-row placeholder-row--mt--10  placeholder-footer__bottom-advertising animation" />
      <div className="placeholder-row placeholder-row--mt--10 placeholder-footer__bottom-full animation" />
      <div className="placeholder-row placeholder-row--mt--10 placeholder-footer__bottom-copyright animation" />
    </div>
  );
};

export default FooterLocationsPlaceholder;
