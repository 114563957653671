import styled, { css } from 'styled-components';
import { Typography } from '../ui/Typography';
import { NewRadioButtonProps } from './types';

export const RadioContainer = styled.label<
  Pick<NewRadioButtonProps, 'markerPosition'>
>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17.5px 15px;
  background-color: ${({ theme }) => theme.colors.background.primary};
  cursor: pointer;

  ${({ markerPosition }) =>
    markerPosition === 'left' &&
    css`
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: 5px;
    `}

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: calc(100% - 15px);
    height: 0.5px;
    background-color: ${({ theme }) => theme.colors.background.secondary};
  }

  &:hover {
    span {
      color: ${({ theme }) => theme.colors.systemColors.blue};
    }

    > span:nth-child(3) {
      border: 1px solid ${({ theme }) => theme.colors.systemColors.blue};
    }
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

  -webkit-tap-highlight-color: transparent;
`;

export const RadioInput = styled.input`
  &:checked {
    ~ span:nth-child(3) {
      border: 1px solid ${({ theme }) => theme.colors.systemColors.blue};

      > span {
        background-color: ${({ theme }) => theme.colors.systemColors.blue};
      }
    }
  }

  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: -1;
`;

export const RadioText = styled(Typography)<{
  color?: string;
  isChecked: boolean;
}>`
  color: ${({ color, isChecked, theme }) =>
    isChecked ? color : theme.colors.text.primary};
`;

export const MarkContainer = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ededed;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
`;

export const Mark = styled.span`
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
`;
