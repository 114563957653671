import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import Icon from '../../Icon';
import CloseIcon from '../../../public/static/images/new_ad/icons/icon-close.svg';
import OptionComponent from '../OptionComponent';
import { useNewItemLocalState } from '../../../hooks/useNewItemLocalState';
import useBodyScrollLock from '../../../hooks/useBodyScrollLock';
import { fillAndSortCategories } from '../../../utils/helpers/itemForm';
import { NewRadioButton } from '../../NewRadioButton';
import { useAnimationDelay } from '../../../hooks/useAnimationDelay';
import { categoriesQuery } from '../../../queries';
import { setError } from '../../../actions';

const SelectCategoryModal = ({
  hide,
  isOpen,
  currentValue,
  closeLeasedModal,
  isShowBackButton = true,
  isCreateNewItemPage = false,
  callBack
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(categoriesQuery);
  const categoriesData = loading ? [] : data?.categories;
  const filledCategories = fillAndSortCategories(categoriesData);
  const animate = useAnimationDelay(isOpen);

  const { setSecondViewValue } = useNewItemLocalState();
  useBodyScrollLock(true);

  const openAddItemModal = () => router.push('/items/new');

  const hideAndCloseLeasedModal = () => {
    hide();
    closeLeasedModal();
  };

  const handleOptionClick = item => {
    setSecondViewValue(item?.id);
    if (isCreateNewItemPage) {
      callBack({ categoryName: item?.name, categoryId: item?.id });
    } else {
      openAddItemModal();
    }
  };

  const { title, styleOptions } = isCreateNewItemPage
    ? {
        title: `${t('new_search.type_estate')} *`,
        styleOptions: 'leased-modal__content--create'
      }
    : {
        title: t('new_ad.select_type_estate'),
        styleOptions: 'leased-modal__content'
      };

  useEffect(() => {
    if (error) dispatch(setError(true));
  }, [error]);

  return (
    <>
      {isOpen && (
        <div
          tabIndex={0}
          role="button"
          className="leased-modal__overlay"
          onClick={hideAndCloseLeasedModal}
          onKeyDown={hideAndCloseLeasedModal}
        />
      )}
      <div
        className={cn('item-modal', {
          opened: isOpen,
          closed: !isOpen
        })}
      >
        {(isOpen || animate) && (
          <div className="leased-modal">
            <div className="leased-modal__header">
              {isShowBackButton && (
                <button
                  type="button"
                  className="leased-modal__header__back-btn"
                  onClick={hide}
                />
              )}
              {title}
              <button
                type="button"
                className="leased-modal__header__close-btn"
                onClick={hideAndCloseLeasedModal}
              >
                <Icon IconComponent={CloseIcon} indent={false} />
              </button>
            </div>
            <div className={styleOptions}>
              {filledCategories.map(item =>
                isCreateNewItemPage ? (
                  <NewRadioButton
                    key={item.id}
                    data={item}
                    cypress="cat-item"
                    currentValue={currentValue}
                    onChangeHandler={() => handleOptionClick(item)}
                  />
                ) : (
                  <OptionComponent
                    key={item.id}
                    text={item.name}
                    icon={item.icon}
                    cypress="cat-item"
                    onClick={() => handleOptionClick(item)}
                  />
                )
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

SelectCategoryModal.propTypes = {
  callBack: PropTypes.func,
  currentValue: PropTypes.string,
  hide: PropTypes.func.isRequired,
  isShowBackButton: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  isCreateNewItemPage: PropTypes.bool,
  closeLeasedModal: PropTypes.func.isRequired
};

export default SelectCategoryModal;
