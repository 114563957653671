import React from 'react';
import CONFIG from '../../config';
import { FlexedFooter, Wrapper } from './styled';
import About from './About';
import ContactUs from './ContactUs';
import Copyright from './Copyright';
import { Locations } from './Locations';
import { StaticFooterDataQuery } from '../../graphql/graphql';
import { useClient } from '../../hooks/useClient';

interface FooterProps {
  isTabBarExist: boolean;
  isBackgroundBanner: boolean;
  footerData: StaticFooterDataQuery;
}

const Footer: React.FC<FooterProps> = ({
  isTabBarExist,
  isBackgroundBanner,
  footerData
}) => {
  const isClient = useClient();

  const {
    contacts: {
      instagramLink,
      facebookLink,
      turboAppStoreLink,
      turboPlayMarketLink,
      tel_additional: telAdditional,
      tel_additional_url: telAdditionalUrl,
      email
    },
    currentYear
  } = CONFIG;

  return (
    <FlexedFooter
      bottomSpace={isClient && isTabBarExist}
      isBackgroundBanner={isClient && isBackgroundBanner}
      data-cy="footer"
      id="footer"
    >
      <Wrapper>
        <ContactUs
          {...{
            turboAppStoreLink,
            turboPlayMarketLink,
            telAdditional,
            telAdditionalUrl,
            email
          }}
        />
        <Locations footerData={footerData} />
        <About />
        <Copyright {...{ currentYear, instagramLink, facebookLink }} />
      </Wrapper>
    </FlexedFooter>
  );
};

export default Footer;
