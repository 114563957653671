import Link from 'next/link';
import styled from 'styled-components';
import { FlexibleDiv } from '../../../styled/common';

export const AboutContainer = styled(FlexibleDiv)`
  width: 100%;
  align-items: center;
  padding: 20px 0;
  gap: 15px;
  border-top: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.primary};

  @media screen and (max-width: 1439px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 25px 0 20px;
    border-top: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
    padding: 15px 0;
  }
`;

export const StyledLinkTitle = styled(Link)`
  font-size: 12px;
  line-height: normal;
  transition: color 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.systemColors.brand};
  }

  @media screen and (max-width: 1439px) {
    font-size: 13px;
    line-height: 20px;
  }
`;

export const DesktopSwitcherContainer = styled.div`
  & button {
    cursor: pointer;
    background-color: transparent;
    font-size: 12px;
    padding: 0;
    transition: color 0.2s ease-in-out;
    color: ${({ theme }) => theme.colors.text.primary};

    @media screen and (max-width: 1439px) {
      font-size: 13px;
    }
  }

  &:hover button {
    color: ${({ theme }) => theme.colors.systemColors.brand};
  }
`;
