import { useEffect } from 'react';

const useBodyScrollLock = lock => {
  useEffect(() => {
    document.body.style.overflow = lock ? 'hidden' : 'unset';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [lock]);
};

export default useBodyScrollLock;
