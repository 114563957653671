import { useEffect } from 'react';

export const useLockBodyScroll = (isOpen, isOverflowOnly = false) => {
  useEffect(() => {
    const filterModal = document.querySelector('[data-cy="filter-modal"]');
    const filterModalOverlay = document.querySelector(
      '[data-cy="filter-modal"]'
    );
    if (filterModal && filterModalOverlay) {
      filterModal.addEventListener('touchmove', e => e.preventDefault());
      filterModalOverlay.addEventListener('touchmove', e => e.preventDefault());
    }

    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    if (!isOverflowOnly) {
      document.body.style.position = isOpen ? 'fixed' : 'unset';
      document.body.style.height = isOpen ? '100dvh' : 'unset';
      document.body.style.width = isOpen ? '100vw' : 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
      if (!isOverflowOnly) {
        document.body.style.position = 'unset';
        document.body.style.height = 'unset';
        document.body.style.width = 'unset';
      }
    };
  }, [isOpen]);
};
