export const sortIntoVerticalColumns = <T>(items: T, columns: number) => {
  const rows = Math.ceil(items.length / columns);
  const rearranged = Array.from({ length: items.length });
  const sortedByName = [...items].sort((a, b) => a.name.localeCompare(b.name));

  sortedByName.forEach((item, index) => {
    const col = Math.floor(index / rows);
    const row = index % rows;
    rearranged[row * columns + col] = item;
  });

  return rearranged;
};
