import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dynamic from 'next/dynamic';
import { useIphoneDetection } from '../../../hooks/useIphoneDetection';
import useScreenSize from '../../../hooks/useScreenSize';
import {
  ContactsInfoBlock,
  ContactsInfoItem,
  ContactsInfoItems,
  ContactsInfoItemValue,
  ContactUsWrapper,
  QrCodeInfoContainer,
  TurboAppDescription,
  TurboAppLinkedTitle,
  TurboAppLinksBlock,
  TurboAppQrCodeLinked
} from './styled';

const ContactsInfoBlockTitle = dynamic(
  () => import('./styled').then(mod => mod.ContactsInfoBlockTitle),
  { ssr: false }
);

const ContactsInfoItemTitle = dynamic(
  () => import('./styled').then(mod => mod.ContactsInfoItemTitle),
  { ssr: false }
);

const TurboLogo = dynamic(
  () => import('./TurboLogo').then(mod => mod.TurboLogo),
  { ssr: false }
);

const TurboQRCode = dynamic(
  () => import('./TurboQRCode').then(mod => mod.TurboQRCode),
  { ssr: false }
);

const ContactUs = ({
  turboAppStoreLink,
  turboPlayMarketLink,
  telAdditional,
  telAdditionalUrl,
  email
}) => {
  const { isLarge } = useScreenSize();
  const { t } = useTranslation();
  const isIphone = useIphoneDetection();

  const contactsInfoList = [
    {
      title: t('common.phone'),
      value: telAdditional,
      path: telAdditionalUrl,
      cypress: 'footer-link-phone'
    },
    {
      title: t('common.email'),
      value: email,
      path: `mailto:${email}`,
      cypress: 'footer-link-social'
    }
  ];

  return (
    <ContactUsWrapper data-cy="contact-us-block">
      <ContactsInfoBlock>
        {!isLarge && (
          <ContactsInfoBlockTitle data-cy="contact-us-title">
            {t('common.contact_us')}
          </ContactsInfoBlockTitle>
        )}
        <ContactsInfoItems data-cy="contact-us-items">
          {contactsInfoList.map(({ title, value, path, cypress }, index) => {
            return (
              <ContactsInfoItem key={index}>
                {isLarge && (
                  <ContactsInfoItemTitle data-cy={`${cypress}-title`}>
                    {title}
                  </ContactsInfoItemTitle>
                )}
                <ContactsInfoItemValue href={path} data-cy={`${cypress}-value`}>
                  {value}
                </ContactsInfoItemValue>
              </ContactsInfoItem>
            );
          })}
        </ContactsInfoItems>
      </ContactsInfoBlock>
      <TurboAppLinksBlock>
        <TurboLogo />
        <QrCodeInfoContainer data-cy="qr-container">
          <TurboAppQrCodeLinked
            href={isIphone ? turboAppStoreLink : turboPlayMarketLink}
            target="_blank"
            data-cy="qr-turbo-container"
          >
            <TurboQRCode />
            <TurboAppLinkedTitle data-cy="qr-turbo-title">
              {t('common.turbo_app')}
            </TurboAppLinkedTitle>
          </TurboAppQrCodeLinked>
          <TurboAppDescription data-cy="qr-turbo-description">
            {t('common.turbo_slogan')}
          </TurboAppDescription>
        </QrCodeInfoContainer>
      </TurboAppLinksBlock>
    </ContactUsWrapper>
  );
};

ContactUs.propTypes = {
  turboAppStoreLink: PropTypes.string,
  turboPlayMarketLink: PropTypes.string,
  telAdditional: PropTypes.string,
  telAdditionalUrl: PropTypes.string,
  email: PropTypes.string
};

export default ContactUs;
