import styled from 'styled-components';
import { Typography } from '../../ui/Typography';
import { FontWeight } from '../../../styled/constants';

export const LocationsContainer = styled.div`
  padding-bottom: 30px;
`;

export const LocationsTitle = styled(Typography)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: ${FontWeight.medium};
  margin: 15px 0;
  display: block;
`;

export const LocationsBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(7, minmax(70px, 1fr));
  max-width: 100%;
  padding: 10px;

  @media screen and (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
`;

export const LocationTitle = styled.a`
  color: ${({ theme }) => theme.colors.text.secondary};
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.systemColors.brand};
  }
`;
