import FlatIcon from '../../public/static/images/new_ad/icons/icon-flat.svg';
import VillaIcon from '../../public/static/images/new_ad/icons/icon-villa.svg';
import OfficeIcon from '../../public/static/images/new_ad/icons/icon-office.svg';
import GarageIcon from '../../public/static/images/new_ad/icons/icon-garage.svg';
import LandIcon from '../../public/static/images/new_ad/icons/icon-land.svg';
import ObjectIcon from '../../public/static/images/new_ad/icons/icon-object.svg';

export const fillAndSortCategories = categories => {
  const customOrder = ['1', '5', '10', '9', '7', '8'];

  const categoriesDataMap = {
    1: {
      icon: FlatIcon
    },
    5: {
      icon: VillaIcon
    },
    7: {
      icon: OfficeIcon
    },
    8: {
      icon: GarageIcon
    },
    9: {
      icon: LandIcon
    },
    10: {
      icon: ObjectIcon
    }
  };

  const filledRes = (categories || []).map(category => {
    const { id, name } = category;
    const newData = categoriesDataMap[id];
    return {
      id,
      name,
      ...newData
    };
  });

  return filledRes.sort((a, b) => {
    const indexA = customOrder.indexOf(a.id);
    const indexB = customOrder.indexOf(b.id);
    return indexA - indexB;
  });
};
