import styled from 'styled-components';
import { FlexibleDiv } from '../../../styled/common';
import { Typography } from '../../ui/Typography';
import LinkWithIcon from '../../LinkWithIcon';

export const CopyrightContainer = styled(FlexibleDiv)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;

  @media screen and (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 10px 0;
    gap: 10px;
  }
`;

export const LinksContainer = styled(FlexibleDiv)`
  justify-content: flex-end;
  gap: 20px;

  & svg path {
    fill: ${({ theme }) => theme.colors.text.secondary};
  }

  @media screen and (max-width: 1439px) {
    gap: 10px;
  }
`;

export const GrayText = styled(Typography).attrs({
  tag: 'caption_1'
})`
  display: block;
  color: ${({ theme }) => theme.colors.text.secondary};

  @media screen and (max-width: 1439px) {
    width: 417px;
  }

  @media screen and (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
    width: 100%;
  }
`;

export const ChangeLanguageButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & button {
    cursor: pointer;
    background-color: transparent;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.text.secondary};
    transition: color 0.3s ease-in-out;
  }

  svg path {
    transition: fill 0.3s ease-in-out;
  }

  &:hover button,
  &:focus button,
  &:hover svg path,
  &:focus svg path {
    color: ${({ theme }) => theme.colors.systemColors.brand};
    fill: ${({ theme }) => theme.colors.systemColors.brand};
  }

  @media screen and (max-width: 1439px) {
    margin-left: 10px;

    & button {
      font-size: 13px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
    margin-left: 17px;
  }
`;

export const GrayLinkWithIcon = styled(LinkWithIcon)`
  display: flex;
  align-items: center;

  svg path {
    transition: fill 0.3s ease-in-out;
  }

  &:hover svg path,
  &:focus svg path {
    color: ${({ theme }) => theme.colors.systemColors.brand};
    fill: ${({ theme }) => theme.colors.systemColors.brand};
  }
`;

export const CopyrightAuthorRules = styled(GrayText)`
  margin-top: 5px;

  @media screen and (max-width: 1439px) {
    font-size: 14px;
    margin-top: 15px;
  }

  @media screen and (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
    margin-top: 10px;
  }
`;
