import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  getReversedLangDefinition,
  makeUrlWithLang
} from '../../utils/helpers';
import { withBridgeState } from '../../bridge';

class ChangeLang extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  static propTypes = {
    t: PropTypes.func,
    i18n: PropTypes.object,
    className: PropTypes.string,
    stat: PropTypes.string,
    bridgeState: PropTypes.object,
    icon: PropTypes.node,
    pathnameToReplace: PropTypes.string
  };

  handleClick() {
    const { language } = this.props.i18n;
    const { pathname, host, protocol, search } = window.location;
    const newLocation = makeUrlWithLang({
      protocol: protocol.substring(0, protocol.length - 1),
      host,
      path: `${this.props.pathnameToReplace || pathname}${search}`,
      lang: getReversedLangDefinition(language)
    });

    window.location.replace(newLocation);
  }

  render() {
    if (this.props.bridgeState.isWebView) {
      return null;
    }

    const {
      t,
      i18n: { language },
      className,
      stat,
      icon
    } = this.props;
    const classNames = cn(className, 'lang-switcher');

    return (
      <button
        className={classNames}
        onClick={this.handleClick}
        data-cy="lang-switcher"
        data-stat={stat}
      >
        {icon && <i>{icon}</i>}
        {t('main_menu.lang', { lng: getReversedLangDefinition(language) })}
      </button>
    );
  }
}
export default withBridgeState(ChangeLang);
