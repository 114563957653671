import { useApolloClient, useQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import useBugsnagNotifier from './useBugsnagNotifier';

interface UseStaticDataResult<T> {
  data: T | undefined;
  loading: boolean;
}

export const useStaticData = <T>(
  request: DocumentNode,
  skip = false
): UseStaticDataResult<T> => {
  const client = useApolloClient();

  const dataFromCache = client.readQuery({ query: request });

  const { data, error, loading } = useQuery<T>(request, {
    skip: !!dataFromCache || skip
  });

  useBugsnagNotifier(error);
  return { data: dataFromCache || data, loading };
};
