import React, { FC, useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import Icon from '../Icon';
import {
  setChosenLocationNames,
  setCurrentCity,
  setCurrentState,
  toggleSideMenuModal,
  toggleAddNewItemModal,
  setLocationIds,
  setAdriverSelectedCity
} from '../../actions';
import { selectSideMenuModalState } from '../../store/selectors';
import { useNewItemLocalState } from '../../hooks/useNewItemLocalState';
import { ICustomTabBarItem } from './types';
import {
  TabBarListItem,
  TabBarLink,
  TabBarIconWrapper,
  TabBarLinkText
} from './styled';
import { BUILDING_TYPE_ID, Cities } from '../../utils/helpers/constants';
import ButtonPlaceholder from '../Placeholders/ButtonPlaceholder';

interface TabBarItemProps {
  item: ICustomTabBarItem;
  isAuthInProgress: boolean;
}

const TabBarItemAuth = dynamic(
  () => import('./TabBarItemAuth').then(mod => mod.TabBarItemAuth),
  { ssr: false, loading: ButtonPlaceholder }
);

export const TabBarItem: FC<TabBarItemProps> = ({ item, isAuthInProgress }) => {
  const {
    routes,
    path,
    name,
    icon,
    text,
    isSideMenuTab,
    isLink,
    iconW,
    iconH,
    isAddNew,
    eraseData,
    isProfileTab
  } = item;

  const dispatch = useDispatch();
  const router = useRouter();
  const { pathname } = router;
  const currentPath = router.asPath.split('#')[0];
  const { isVisible: isVisibleSideMenuModal } = useSelector(
    selectSideMenuModalState
  );
  const { setFirstViewValue } = useNewItemLocalState();

  const statNaming = useMemo(
    () => (statName: string) =>
      currentPath.includes('/profile') ? `profile-${statName}` : statName,
    [currentPath]
  );

  const isActiveTab = useMemo(() => {
    if (isVisibleSideMenuModal) return isSideMenuTab;
    return routes.includes(pathname);
  }, [isVisibleSideMenuModal, isSideMenuTab, routes, pathname]);

  const openSideMenuModalHandler = () => {
    dispatch(toggleSideMenuModal(true));
    router.push(`${currentPath}#more`, undefined, { shallow: true });
  };

  const closeSideMenuModalHandler = () => {
    if (eraseData) {
      const resetData = {
        city_id: Cities.Baku.id,
        leased: 'false',
        category_id: BUILDING_TYPE_ID.FLAT_NEW,
        location_ids: undefined,
        category_touched: false
      };
      dispatch(setChosenLocationNames([]));
      dispatch(setLocationIds([]));
      dispatch(setCurrentCity('1'));
      dispatch(setCurrentState(resetData));
      dispatch(setAdriverSelectedCity(Cities.Baku.az));
    }
    dispatch(toggleSideMenuModal(false));
  };

  const onProfileItemClickHandler = () => {
    closeSideMenuModalHandler();
    router.push(`${currentPath}#authentication`, undefined, { shallow: true });
  };

  const openCreateNewItemForm = () => {
    dispatch(toggleAddNewItemModal(true));
    setFirstViewValue('false');
  };

  const onClickDivContainerHandler = useMemo(
    () =>
      isSideMenuTab ? openSideMenuModalHandler : onProfileItemClickHandler,
    [isSideMenuTab, openSideMenuModalHandler, onProfileItemClickHandler]
  );

  const tabBarItemContent = (
    <>
      <TabBarIconWrapper>
        <Icon
          indent={false}
          width={iconW}
          height={iconH}
          color={isActiveTab ? 'dark' : 'brown'}
          IconComponent={icon}
        />
      </TabBarIconWrapper>
      <TabBarLinkText $active={isActiveTab}>{text}</TabBarLinkText>
    </>
  );

  let renderedComponent;

  switch (true) {
    case isProfileTab:
      renderedComponent = (
        <TabBarItemAuth
          path={path}
          onClick={onClickDivContainerHandler}
          isActive={isActiveTab}
          isAuthInProgress={isAuthInProgress}
        >
          {tabBarItemContent}
        </TabBarItemAuth>
      );
      break;

    case isAddNew:
      renderedComponent = (
        <TabBarLink
          role="button"
          tabIndex={0}
          onClick={openCreateNewItemForm}
          onKeyPress={openCreateNewItemForm}
          centered
          data-cy="new-item-bar-link"
          data-stat={statNaming('new-item-bar-link')}
        >
          {tabBarItemContent}
        </TabBarLink>
      );
      break;

    case isLink:
      renderedComponent = (
        <Link
          href={path}
          onClick={closeSideMenuModalHandler}
          data-cy={`${name}-bar-link`}
          data-stat={statNaming(`${name}-bar-link`)}
          style={{ textDecoration: 'none' }}
          locale={false}
        >
          <TabBarLink as="div" $active={isActiveTab}>
            {tabBarItemContent}
          </TabBarLink>
        </Link>
      );
      break;

    default:
      renderedComponent = (
        <TabBarLink
          role="button"
          onKeyPress={onClickDivContainerHandler}
          onClick={onClickDivContainerHandler}
          tabIndex={0}
          data-cy={`${name}-bar-button`}
          data-stat={statNaming(`${name}-bar-button`)}
          active={isActiveTab}
        >
          {tabBarItemContent}
        </TabBarLink>
      );
      break;
  }

  return <TabBarListItem>{renderedComponent}</TabBarListItem>;
};
