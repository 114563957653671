import styled, { css } from 'styled-components';
import { FlexCenterAligned } from '../../styled/common';

export const FlexedFooter = styled(FlexCenterAligned).attrs({ as: 'footer' })`
  background-color: ${({ theme }) => theme.colors.background.tertiary};
  ${({ isBackgroundBanner }) =>
    isBackgroundBanner &&
    css`
      z-index: 70000;
    `} // to hide bg-banner

  ${({ bottomSpace }) =>
    bottomSpace &&
    css`
      padding-bottom: 40px;
    `};
`;

export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;

  @media screen and (max-width: ${({ theme }) => theme.desktopBreakpoint}) {
    padding: 15px 40px;
    border-top: 1px solid ${({ theme }) => theme.colors.border.primary};
  }

  @media screen and (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
    padding: 20px 15px;
  }

  @media screen and (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    margin: 0 auto;
    max-width: 1000px;
  }
`;
