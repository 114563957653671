import { useState, useEffect } from 'react';

export const useIphoneDetection = () => {
  const [isIphone, setIsIphone] = useState(null);

  useEffect(() => {
    const isIphoneTest = /iPhone/.test(navigator.userAgent) && !window.MSStream;

    setIsIphone(isIphoneTest);
  }, []);

  return isIphone;
};
